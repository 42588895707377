.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  /*font-family: 'Source Serif Pro', serif;*/
}

.SideBySide {
  display: flex;
  justify-content: center;
}

.backtotop :hover {
  color: black;
  font-weight: bolder;
}

.divider {
  background: orange;
  height: 2px;
  margin-top: 0.5em;
  margin-bottom: 2em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.About {
  font-family: "Montserrat", sans-serif;
}

.Name {
  font-size: 1.2em;
  font-family: "Montserrat", sans-serif;
  /*font-weight: bold;*/
}

.Content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  text-align: center;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar {
  position: sticky!important;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
  background-color: white; /* Optional: set the background color to prevent transparency issues */
  width: 100%; /* Optional: make sure it spans the entire width */
}

.navbar-item {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.navbar-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transition: background-color 0.3s ease;
}

.dark-mode .navbar {
  background-color: black;
  color: white;
}

.dark-mode .navbar-item {
  color: white;
}

.dark-mode .navbar-item:hover {
  color: orange;
}

.navbar-item:hover {
  border-bottom: 1.5px solid orange;
  font-weight: bold;
  color: black !important;
  cursor: pointer;
  font-size: large;
}

.backtotop {
  color: navy !important;
  text-decoration: underline;
}

.backtotop:hover {
  font-weight: bold;
  font-size: larger;
  color: black !important;
}
.contact-info {
  margin-top: 30px;
  position: fixed;
  top: 60px;
  right: 10px;
  padding: 10px;
  /* border: 0.5px solid #ccc; */
  border-radius: 25px;
  z-index: 9999;
  color: black;
  font-weight: 300;
}
.typing {
  color: black;
  overflow: hidden;
  white-space: normal;
  margin: 0 auto;
  letter-spacing: 0.08em;
  font-size: 3.1em;
  width: 100%;
  font-weight: bold;
}

.typing.dark-mode {
  color: white;
}

@media screen and (max-width: 600px) {
  .typing {
    font-size: 2.5em; /* Adjust the font size as needed */
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}


body {
  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s;
}

/* Dark mode styles */
body.dark-mode {
  background-color: black;
  color: white;
}

.App.dark-mode {
  background-color: black;
  color: white;
}

.dark-mode b {
  color: white;
}